import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Holed up in my room, a bookworm resides,`}<br parentName="p"></br>{`
`}{`Together with friends, it secretly hides.`}<br parentName="p"></br>{`
`}{`They travel in concert, sneak out when it’s late,`}<br parentName="p"></br>{`
`}{`Abandoning me, in the time I most hate.`}<br parentName="p"></br>{`
`}{`For kingdoms, forests, seas: all a delight.`}<br parentName="p"></br>{`
`}{`Morphing into creatures of beauty and fright.`}</p>
    <p>{`Bitter green, I sit and wait,`}<br parentName="p"></br>{`
`}{`This feeling I must quickly abate,`}<br parentName="p"></br>{`
`}{`Not envy. No, anger! I’m angry alright.`}<br parentName="p"></br>{`
`}{`A lawful response to this unjust plight.`}<br parentName="p"></br>{`
`}{`Why must they always leave me alone?`}<br parentName="p"></br>{`
`}{`I’m not a dirty rag, used and later thrown.`}</p>
    <p>{`It was different once, I recall,`}<br parentName="p"></br>{`
`}{`When I was still very small -`}<br parentName="p"></br>{`
`}{`They took me with them, to ride the moon:`}<br parentName="p"></br>{`
`}{`I’m drowning in memories, too heavy to prune.`}<br parentName="p"></br>{`
`}{`Days filled with laughter, no panic, no fret…..`}<br parentName="p"></br>{`
`}{`These precious memories I mustn't forget.`}</p>
    <p>{`My hope was relinquished a long time ago,`}<br parentName="p"></br>{`
`}{`They will never take me, stricken by woe.`}<br parentName="p"></br>{`
`}{`Wrongfully cursed, I have slowly grown,`}<br parentName="p"></br>{`
`}{`For metamorphism I must now atone,`}<br parentName="p"></br>{`
`}{`My innocent smile, ripped away, gone -`}<br parentName="p"></br>{`
`}{`My face composed, I await dawn.`}</p>
    <p>{`The sun is up, and they are here,`}<br parentName="p"></br>{`
`}{`No more than whispers in the air.`}<br parentName="p"></br>{`
`}{`Their hushed voices heard only by me,`}<br parentName="p"></br>{`
`}{`My room locked, I hold the key.`}<br parentName="p"></br>{`
`}{`No one must know of their existence,`}<br parentName="p"></br>{`
`}{`Society will make me keep my distance.`}<br parentName="p"></br>{`
`}{`Hands outstretched, I approach them; calm,`}<br parentName="p"></br>{`
`}{`But they turn into heavy books in my palm.`}</p>
    <p>{`Holed up in our room, my bookworm resides,`}<br parentName="p"></br>{`
`}{`Smiling, as I look on with sad eyes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      